import React from "react";
import styles from "./styles.module.css";

export default function Logiciels() {
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <h1>Logiciels</h1>
        <h2>Compatibilité logicielle garantie</h2>
        <p>
          Deluxe a des chèques et des formulaires compatibles avec presque tous
          les logiciels. Si vous ne pouvez pas trouver les produits dont vous
          avez besoin, appelez-nous simplement au 1 800 461 9666 ou envoyez un
          échantillon imprimé rempli — incluant le fabricant du logiciel et le
          nom du programme — à :
        </p>
        <p>
          <span>Produits d'Affaires Deluxe limitée</span>
          <br />
          Renseignements sur les produits
          <br />
          330 Cranston Crescent
          <br />
          Midland ON L4R 4V9
        </p>
        <p>Nous vous procurerons les formulaires qu'il vous faut !</p>
        <p>
          Vous trouverez ci-dessous les progiciels les plus courants. Suivez les
          liens correspondant à ceux que vous employez pour découvrir quels
          produits sont disponibles et rapidement utilisables.
        </p>
      </div>
    </div>
  );
}
