import React from "react";
import Layout from "../../../other/layout";
import Logiciels from "../../../components/AC-StaticPages/Gerez/Logiciels/Logiciels";
import SEO from "../../../other/seo";

export default function logiciels() {
  return (
    <Layout>
      <SEO title="Logiciels" />

      <Logiciels />
    </Layout>
  );
}
